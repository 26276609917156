import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fallback"];

  expand() {
    if (!navigator.share) {
      this.fallbackTarget.classList.toggle("is-active");
    }
  }
}
