/* eslint no-restricted-globals: off */
import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["filterable", "source", "hashchanger", "report"];

  static debounces = ["count"];

  connect() {
    useDebounce(this, { wait: 100 });
  }

  // eslint-disable-next-line class-methods-use-this
  createStyle(e) {
    const targetId = e.target.id;
    if (!document.querySelector(`#${targetId}-hide`)) {
      const stylesheet = document.createElement("style");
      stylesheet.id = `${targetId}-hide`;
      stylesheet.innerText = `.${targetId}-hide{display:none;}`;
      document.head.appendChild(stylesheet);
    }
  }

  count() {
    let count = 0;
    this.filterableTargets.forEach((el) => {
      if (window.getComputedStyle(el).display !== "none") {
        count += 1;
      }
    });
    this.reportTarget.innerHTML = count;
  }

  filter(e) {
    const source = e.target;
    const sourceValue = source.value;
    const hiddenClass = `${source.id}-hide`;
    this.filterableTargets.forEach((el) => {
      if (sourceValue === "*" || sourceValue === "") {
        // If none is chosen, then clear all hidden nodes
        if (el.matches(".description")) {
          el.classList.add(hiddenClass);
        } else {
          el.classList.remove(hiddenClass);
        }
      } else {
        // Hide all
        el.classList.add(hiddenClass);
        // find divs with category and unhide them
        if (el.classList.contains(sourceValue)) {
          el.classList.remove(hiddenClass);
        }
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  hashChange(e) {
    const source = e.target;
    const sourceValue = source.value;
    // Change hash to match dropdown value
    // i.e. dropdown -> hash
    if (sourceValue !== "") {
      window.history.replaceState(history.state, null, `#${sourceValue}`);
    } else {
      // Remove hash if value is all
      window.history.replaceState(history.state, null, " ");
    }
  }

  dropdownChange() {
    if (window.location.hash === "") {
      // Change dropdown value to match hash
      // i.e. hash -> dropdown
      window.history.replaceState(history.state, null, " ");
    } else {
      this.hashchangerTarget.value = window.location.hash.replace("#", "");
    }
    // Trigger filtering by forcing a change event
    this.hashchangerTarget.dispatchEvent(new Event("change"));
  }
}
