import { Controller } from "@hotwired/stimulus";
import { useThrottle } from "stimulus-use";

export default class extends Controller {
  static classes = ["stuck"];

  static throttles = ["stickyChecker"];

  connect() {
    useThrottle(this, { wait: 25 });
  }

  stickyChecker() {
    if (window.matchMedia("(min-width: 984px)").matches) {
      // Use this trick to access parent nodes
      // https://discuss.hotwire.dev/t/getting-parent-controller-from-within-child/1053/7
      // Will work on both top menu and sticky nav
      this.element[this.identifier] = this;
      // Get parent
      const navHolder = this.element.closest("nav");

      // Use parent as wrapper
      const navBounds = navHolder.getBoundingClientRect();
      // Also get sticky dimenstions to check if it overflows
      const stickyBounds = this.element.getBoundingClientRect();

      // Don't apply stuck if not yet at top or if taller than viewport
      if (navBounds.top >= 0 || stickyBounds.height > window.innerHeight) {
        this.element.classList.remove(this.stuckClass);
      } else {
        // Add stuck on regular scroll
        this.element.classList.add(this.stuckClass);
      }
    }
  }
}
