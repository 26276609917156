import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import postscribe from "postscribe";

export default class extends Controller {
  static targets = ["container"];

  static values = { url: String };

  connect() {
    useIntersection(this, this.options);
  }

  appear() {
    // Use intersection observer to only load script on appearance
    this.loadScript();
  }

  /*
   * This method uses postscribe.js to inject external javascript that uses document.write
   * Since document.write only works on first load (thus not in SPAs/Turbo), postscribe overrides document.write itself
   * By default this only runs when it appears with intersection observer; this can be overridden with data-action
   *
   * Use this method in templates or CMS content with the following syntax:
   * <div data-controller="scriptloader" data-scriptloader-url-value="https://path.to/external-script.js">
   *   <div data-scriptloader-target="container"></div>
   * </div>
   *
   * Output will be:
   * <div data-controller="scriptloader" data-scriptloader-url-value="https://path.to/external-script.js">
   *   <div data-scriptloader-target="container">
   *     <script src="https://path.to/external-script.js"></script>
   *   </div>
   * </div>
   * ...but the difference is that postscribe has changed document.write to something the SPA/Turbo can use
   */
  loadScript() {
    const scriptEl = this.containerTarget.querySelector("script");
    // Check if script is already created so postscribe don't add it more than once
    if (!scriptEl) {
      postscribe(
        this.containerTarget,
        `<script src=${this.urlValue}></script>`
      );
    }
  }
}
