import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    duration: Number,
  };

  // Start function once it's initialized
  initialize() {
    this.animateIn();
  }

  async remove() {
    // Delay with specified duration
    await this.delay(this.durationValue);
    // Wait for animateOut to finish
    await this.animateOut();

    // After all animations are done, remove element completely
    Promise.all(
      this.element.getAnimations().map((animation) => {
        return animation.finished;
      })
    ).then(() => {
      this.hideOuterElement();
      this.element.classList.add("visually-hidden");
    });
  }

  hideOuterElement() {
    this.dispatch("hideGroup", { prefix: "toast" });
  }

  // Animation that starts on init
  animateIn() {
    this.element.animate(this.fadeIn, this.timing);
    this.element.animate(this.slideUp, this.timing);
    // Set opacity so it doesn't disappear once animation is done
    this.element.style.opacity = 1;
    // Start removal function at once since it runse on a delay
    this.remove();
  }

  async animateOut() {
    // Us do opposite
    this.element.animate(this.fadeOut, this.timing);
    this.element.style.opacity = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  delay(time) {
    // Use promise so we can use await
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  // Options used in methods
  timing = {
    duration: 300,
    easing: "cubic-bezier(0.55, 0.085, 0.68, 0.53)",
  };

  fadeIn = [{ opacity: 0 }, { opacity: 1 }];

  fadeOut = [{ opacity: 1 }, { opacity: 0 }];

  distance = getComputedStyle(this.element).getPropertyValue(
    "--_travel-distance"
  );

  slideUp = [
    { transform: `translateY(${this.distance})` },
    { transform: "translateY(0)" },
  ];
}
