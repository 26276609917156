// eslint-disable-next-line no-unused-vars
import * as Turbo from "@hotwired/turbo";
import "../stylesheets/style.scss";
import "../controllers";
import TheTabs from "@acab/tabs";
import "../components/imgloader";
import { supportedBrowsers } from "../supportedBrowsers";

window.customElements.define("the-tabs", TheTabs);
// Add class to browsers that support modules but are not supported by defaults
if (!supportedBrowsers.test(navigator.userAgent)) {
  document.documentElement.classList.add("unsupported-browser");
}
