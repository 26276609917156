import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";

export default class extends Controller {
  static targets = ["brick", "width"];

  connect() {
    useResize(this);
    this.layout();
  }

  // eslint-disable-next-line no-unused-vars
  resize({ width }) {
    this.layout();
  }

  // This method is adapted from Ana Tudor's excellent article on css-tricks
  // https://css-tricks.com/a-lightweight-masonry-solution/
  // It's simplified due to using Stimulus, specifically due to the following:
  // By explicitly specifying the targets (here called "bricks"), we avoid the nodeType check
  // By using the data-controller, we're querying a single grid, so no need for all the forEach
  // By using connect() and useResize, we don't need extra event listeners
  // TODO: It looks good, but order is incorrect and differs from both Firefox native flag and Isotope
  layout() {
    // Only run if masonry isn't natively supported by css
    if (getComputedStyle(this.element).gridTemplateRows !== "masonry") {
      // Get computed grid gap
      const brickRowGap = parseFloat(getComputedStyle(this.element).rowGap);
      // Get computed number of columns
      const columnCount = getComputedStyle(
        this.element
      ).gridTemplateColumns.split(" ").length;
      // revert to initial positioning, no margin
      this.brickTargets.forEach((el) => el.style.removeProperty("margin-top"));
      // Get positions of the one above and the one below
      this.brickTargets.slice(columnCount).forEach((el, i) => {
        // Bottom edge of the item above
        const brickAbovePosition =
          this.brickTargets[i].getBoundingClientRect().bottom;
        // Top edge of the item below
        const currentBrickPosition = el.getBoundingClientRect().top;
        // Apply negative margin-top to bottom item. param-reassign is innocuous here.
        /* eslint no-param-reassign: ["error", { "props": false }] */
        el.style.marginTop = `${
          brickAbovePosition + brickRowGap - currentBrickPosition
        }px`;
      });
    }
  }
}
