class ImgLoader extends HTMLElement {
  connectedCallback() {
    const img = this.querySelector(".imgloader");
    if (img.complete) {
      img.classList.add("loaded");
      img.classList.add("preloaded");
    } else {
      img.addEventListener("load", this);
      img.addEventListener("error", this);
    }
  }

  disconnectedCallback() {
    const img = this.querySelector(".imgloader");
    if (!img.classList.contains("preloaded")) {
      img.removeEventListener("load", this);
      img.removeEventListener("error", this);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleEvent(event) {
    switch (event.type) {
      case "load":
        event.target.classList.add("loaded");
        break;
      case "error":
        event.target.classList.add("loaded");
        break;
      default:
    }
  }
}

customElements.define("img-loader", ImgLoader);
