/* eslint-disable no-param-reassign */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterable", "filter"];

  initialize() {
    this.filterTargets.forEach((el) => {
      el.checked = "true";
    });
  }

  filter(el) {
    const filterValue = el.target.value;
    // If unchecked, do the following
    if (el.target.checked === false) {
      this.filterableTargets.forEach((e) => {
        // Parse array in data attribute. This is a "backup" of the values so they don't all get removed
        const filterArray = JSON.parse(e.dataset.filterValues);
        // Includes checks array but removes class. This way the value isn't lost
        if (filterArray.includes(filterValue)) {
          e.classList.remove(filterValue);
        }
        // If all classes are removed, remove the filterable
        if (e.classList.length === 0) {
          e.style.display = "none";
        } else {
          e.style.display = "block";
        }
      });
      // toggle should really be enough here, but since Refinery won't play along, it's hard to ascertain, and we may need a duplicate
    } else {
      this.filterableTargets.forEach((e) => {
        const filterArray = JSON.parse(e.dataset.filterValues);
        // if (e.classList.contains(filterValue)) {
        if (filterArray.includes(filterValue)) {
          e.classList.add(filterValue);
        }
        if (e.classList.length === 0) {
          e.style.display = "none";
        } else {
          e.style.display = "block";
        }
      });
    }
  }
}
