import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    title: String,
    description: String,
    success: String,
    error: String,
  };

  // Action when clicking share link
  async share() {
    // Get meta
    const shareData = {
      title: this.titleValue,
      text: this.descriptionValue,
      url: this.urlValue,
    };
    // If web share API is supported, use it
    if (navigator.share) {
      try {
        await navigator
          .share(shareData)
          .then(() => this.add(this.successValue, 3));
      } catch (err) {
        const error = `${this.errorValue}. (${err})`;
        this.add(error, 6);
      }
    }
  }

  add(toastMessage, durationSec) {
    this.dispatch("emit", {
      prefix: "toast",
      detail: {
        message: toastMessage,
        duration: durationSec,
      },
    });
  }
}
