/* eslint class-methods-use-this: "off" */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  // Copy current URL
  copyLink() {
    const textarea = document.createElement("textarea");

    textarea.style.position = "fixed";
    textarea.style.top = "-1px";
    textarea.style.left = "-1px";
    textarea.style.width = "1px";
    textarea.style.height = "1px";
    textarea.style.opacity = 0;
    textarea.style.pointerEvents = "none";

    textarea.value = this.urlValue;

    document.body.appendChild(textarea);

    textarea.select();

    const successMessageAttribute = this.element.dataset.successMessage;
    const errorMessage = "Copy failed";
    try {
      const copiedURL = document.execCommand("copy");
      if (copiedURL) {
        this.add(successMessageAttribute, 6);
      } else {
        this.add(errorMessage, 6);
      }
    } catch (err) {
      const errorMessagePlusErr = `${errorMessage} ${err}`;
      this.add(errorMessagePlusErr, 6);
    }

    // Clean up leftovers
    document.body.removeChild(textarea);
  }

  // Dispatch toast event and let the receiver handle it
  add(toastMessage, durationSec) {
    this.dispatch("emit", {
      prefix: "toast",
      detail: {
        message: toastMessage,
        duration: durationSec,
      },
    });
  }
}
