import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "submit"];

  static values = {
    freeSeats: Number,
    orderText: String,
    waitlistText: String,
    registrationOpens: String,
    registrationNotYetOpen: String,
    chooseDate: String,
  };

  initSubmitButton() {
    this.selectTarget.dispatchEvent(new Event("change"));
  }

  assignButtonText(event) {
    // Get selected option
    const [option] = event.target.selectedOptions;
    // Get the data attribute from the selected option
    const { freeSeats } = option.dataset;
    const { bookableFromText } = option.dataset;
    // Get epoch time from data attribute (output by Ruby)
    const rubyEpoch = option.dataset.bookableFrom;

    if (option.value === "") {
      // If first empty value, disable form and show choose date text
      this.submitTarget.disabled = true;
      this.submitTarget.value = this.chooseDateValue;
    } else if (rubyEpoch < Date.now()) {
      // If ruby epoch is before the current javascript epoch, enable submit
      this.submitTarget.disabled = false;
      // If seats are not available, use waitlist
      if (freeSeats < 1) {
        this.submitTarget.value = this.waitlistTextValue;
      } else {
        this.submitTarget.value = this.orderTextValue;
      }
    } else {
      // If ruby epoch is in the future
      // Change button text to registrationNotYetOpenValue
      this.submitTarget.value = this.registrationNotYetOpenValue;
      // Create toastMessage from data attributes and set to 6 seconds
      const toastMessage = `${this.registrationOpensValue} ${bookableFromText}`;
      const toastDuration = 6;
      this.dispatchClosedMessage(toastMessage, toastDuration);
      // Disable submit
      this.submitTarget.disabled = true;
    }
  }

  // This event will be listened to by the toast receiver and created with javascript
  dispatchClosedMessage(message, duration) {
    this.dispatch("emit", {
      prefix: "toast",
      detail: {
        message,
        duration,
      },
    });
  }
}
