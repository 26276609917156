/* eslint class-methods-use-this: off */
/* eslint no-param-reassign: off */
import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    useClickOutside(this, { element: this.menuTarget });
  }

  disconnect() {
    this.closeNav();
  }

  closeWithEsc(event) {
    if (this.element.classList.contains("menu-open")) {
      if (event.keyCode === 27) {
        this.closeNav();
      }
    }
  }

  submenuToggle(event) {
    event.preventDefault();
    event.target.closest("li").classList.toggle("submenu-open");
  }

  clickOutside(event) {
    if (this.element.classList.contains("menu-open")) {
      event.preventDefault();
      this.closeNav();
    }
  }

  toggleNav(event) {
    event.preventDefault();
    if (this.element.classList.contains("menu-open")) {
      this.removeClass();
    } else {
      this.addClass();
    }
  }

  closeNav() {
    // event.preventDefault();
    if (this.element.classList.contains("menu-open")) {
      this.removeClass();
    }
  }

  addClass() {
    // Actual function
    this.menuTarget.classList.add("not-hidden");
    setTimeout(() => {
      this.element.classList.add("menu-open");
    }, 50);
  }

  removeClass() {
    if (this.submenuOpenEl) {
      this.submenuOpenEl.classList.remove("submenu-open");
    }
    // this.element.classList.remove(this.openClass);
    this.element.classList.remove("menu-open");

    setTimeout(() => {
      this.menuTarget.classList.remove("not-hidden");
    }, 300);
  }

  get submenuLink() {
    return this.menuTarget.querySelectorAll(".submenu > a");
  }

  get submenuOpenEl() {
    return this.menuTarget.querySelector(".submenu-open");
  }
}
