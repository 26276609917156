/* eslint class-methods-use-this: "off" */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    if (!localStorage.getItem("newsletterPreference")) {
      this.element.hidden = false;
    }
  }

  // Reusable function that sets localStorage
  setNewsletterPreferenceStorage() {
    localStorage.setItem("newsletterPreference", true);
  }

  removeFadeOut(el, speed) {
    const seconds = speed / 1000;
    const fade = el.style;
    fade.transition = `opacity ${seconds}s ease`;
    fade.opacity = 0;
    setTimeout(function removeElementTimeout() {
      el.parentNode.removeChild(el);
    }, speed);
  }

  dismissClick() {
    this.setNewsletterPreferenceStorage();
    this.removeFadeOut(this.element, 500);
  }

  newsletterClick() {
    this.setNewsletterPreferenceStorage();
  }
}
