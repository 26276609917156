/* eslint-disable no-restricted-globals */
/* eslint no-param-reassign: ["error", { "props": false }] */
/* eslint class-methods-use-this: "off" */
import { Controller } from "@hotwired/stimulus";

const scrollBehaviorValue = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
).matches
  ? "auto"
  : "smooth";

export default class extends Controller {
  static targets = ["ignore"];

  connect() {
    this.addBlankAndNoopener();
  }

  addBlankAndNoopener() {
    // Open all external links in new window and add noopener for security
    // Adapted from a comment here:
    // https://gist.github.com/CrocoDillon/7989214
    const { links } = document;
    for (let i = 0, linksLength = links.length; i < linksLength; i += 1) {
      if (links[i].hostname !== window.location.hostname) {
        links[i].target = "_blank";
        links[i].rel = "noopener";
        // Use this loop for internal same-page anchors as well for performance reasons; we're already looping through all the links.
        // This adds smooth scroll to all clicks on same-page anchor links
      } else if (links[i].getAttribute("href").charAt(0) === "#") {
        links[i].addEventListener("click", (e) => {
          // Ignore links with data-external-links-target="ignore" set
          if (!e.currentTarget.dataset.externalLinksTarget) {
            e.preventDefault();
            // Get href from link target
            const anchor = e.currentTarget.getAttribute("href");
            // Remove hash from anchor to get a string for CSS.escape method
            const anchorWithoutHash = anchor.slice(1);
            // Get link target and escape because javascript/css doesn't like ids starting with digits
            const anchorTarget = document.querySelector(
              `#${CSS.escape(anchorWithoutHash)}`
            );
            // Get anchorTarget and scrollIntoView
            anchorTarget.scrollIntoView({
              behavior: scrollBehaviorValue,
            });
            // Add anchorTarget to history
            window.history.replaceState(
              history.state,
              null,
              `#${anchorTarget.id}`
            );
          }
        });
      }
    }
  }
}
