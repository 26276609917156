/* eslint no-restricted-globals: off */
import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static classes = ["active"];

  options = {
    rootMargin: "-150px 0px -150px -0px",
  };

  connect() {
    useIntersection(this, this.options);
  }

  appear() {
    this.activate();
  }

  disappear() {
    this.deactivate();
  }

  activate() {
    this.navElement.classList.add("active-nav");
    this.element.classList.add(this.activeClass);
  }

  deactivate() {
    this.navElement.classList.remove("active-nav");
    this.element.classList.remove(this.activeClass);
  }

  get pageSlug() {
    return this.element.id;
  }

  get navElement() {
    return document.querySelector(`[data-nav-title='${this.pageSlug}']`);
  }
}
