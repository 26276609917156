import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static classes = ["focus"];

  static targets = ["input"];

  connect() {
    useClickOutside(this, { element: this.inputTarget });
  }

  focusInput() {
    this.element.classList.add(this.focusClass);
    this.inputTarget.focus();
  }

  blurInput(event) {
    if (event.keyCode === 27) {
      this.blur();
    }
  }

  clickOutside() {
    this.blur();
  }

  blur() {
    this.element.classList.remove(this.focusClass);
    this.inputTarget.blur();
  }

  clearField() {
    this.inputTarget.value = "";
  }
}
