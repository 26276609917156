/* eslint no-param-reassign: ["error", { "props": false }] */
import { Controller } from "@hotwired/stimulus";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.lazyLoadInstance = new LazyLoad({});
    }
  }

  preparePrint() {
    // eslint-disable-next-line no-console
    console.log("Preparing document for printing...");
    this.lazyLoadInstance.loadAll();
    document.querySelectorAll("details").forEach((el) => {
      el.setAttribute("open", true);
    });
  }

  printAndLoadAllImgs() {
    this.preparePrint();
    window.print();
  }

  teardown() {
    this.lazyLoadInstance.restoreAll();
    this.lazyLoadInstance.destroy();
  }

  disconnect() {
    if (this.lazyLoadInstance) {
      this.teardown();
    }
  }
}
