import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toast"];

  receive(e) {
    // Get params from dispatch
    const { message } = e.detail;
    const { duration } = e.detail;
    const { link } = e.detail;

    this.createToast(message, duration, link);
  }

  hideElement() {
    // This method is run before the actual removal of the toast node happens.
    // Thus we can't check for existence, but we can check that only one toast is active,
    // which also works. We need to make sure it's the last toast.
    if (
      this.element.querySelectorAll("output:not(.visually-hidden)").length === 1
    ) {
      this.element.classList.add("visually-hidden");
    }
  }

  createToast(message, duration, link) {
    // Create element
    const newToast = document.createElement("output");
    // If link was among the params, wrap the text node in an a href
    if (link) {
      const newLink = document.createElement("a");
      newLink.href = link;
      const newMessage = document.createTextNode(message);
      newLink.appendChild(newMessage);
      newToast.appendChild(newLink);
    } else {
      // Regular toasts that aren't clickable
      const newMessage = document.createTextNode(message);
      newToast.appendChild(newMessage);
    }

    // Make new toast use toast controller
    newToast.dataset.controller = "toast";
    // Set duration value (converted to milliseconds)
    newToast.dataset.toastDurationValue = duration * 1000;
    // Set toast receiver targets to iterate with flip method
    newToast.dataset.toastReceiverTarget = "toast";

    // Hide element at first, it will fade in after it's appended to the DOM
    newToast.style.opacity = 0;
    this.appendToast(newToast);
  }

  flipToast(newToast) {
    // Use FLIP technique
    // Loop through existing targets before adding new one
    this.toastTargets.forEach((el) => {
      // Calculate height of receiver element (i.e. toast group)
      const first = this.element.offsetHeight;
      // Append the new toast to DOM
      this.element.appendChild(newToast);
      // Calculate new height after adding
      const last = this.element.offsetHeight;
      // Calculate height difference
      const invert = last - first;
      // Animate to new position with translateY
      el.animate(
        [
          { transform: `translateY(${invert}px)` },
          { transform: "translateY(0)" },
        ],
        {
          duration: 150,
          easing: "ease-out",
        }
      );
    });
  }

  appendToast(newToast) {
    // Remove hidden attribute from toast-group
    this.element.classList.remove("visually-hidden");
    // Get user motion preference
    const { matches: motionOK } = window.matchMedia(
      "(prefers-reduced-motion: no-preference)"
    );
    // Check if there's a toast present already
    if (this.hasToastTarget && motionOK) {
      this.flipToast(newToast);
    } else {
      // Add element
      this.element.appendChild(newToast);
    }
  }
}
