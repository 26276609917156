import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static targets = ["attended", "attendee"];

  static values = {
    attended: Boolean,
    audienceLimit: Number,
  };

  static debounces = ["loadFrameAndRefreshCount"];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  loadFrameAndRefreshCount() {
    this.setCounter();
  }

  setCounter() {
    const attendeeCount = this.element.querySelectorAll(
      '[data-attendee-attended-value="true"]'
    ).length;
    const attendeeCheckinButtons =
      this.element.querySelectorAll(".attendee-checkin");
    this.attendedTarget.innerHTML = this.audienceLimitValue - attendeeCount;
    const para = this.attendedTarget.closest("li");
    // Disable checkin buttons and make line bold if fully checked in
    if (attendeeCount === this.audienceLimitValue) {
      attendeeCheckinButtons.forEach((el) => {
        const button = el;
        button.disabled = true;
      });
      para.classList.add("bold");
      // Enable checkin buttons and make line regular weight if available seats
    } else {
      attendeeCheckinButtons.forEach((el) => {
        const button = el;
        button.disabled = false;
      });
      para.classList.remove("bold");
    }
  }
}
