import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static targets = ["logo", "menu", "button"];

  // Observe the logo for intersection
  // Because of markup this.element is the body element
  options = {
    element: this.logoTarget,
  };

  connect() {
    useIntersection(this, this.options);
  }

  // eslint-disable-next-line no-unused-vars
  appear(entry) {
    this.removeMiniHomeButton();
  }

  // eslint-disable-next-line no-unused-vars
  disappear(entry) {
    this.addMiniHomeButton();
  }

  addMiniHomeButton() {
    this.menuTarget.classList.add("stuck");
    this.buttonTarget.tabIndex = 0;
    this.buttonTarget.hidden = false;
  }

  removeMiniHomeButton() {
    this.buttonTarget.hidden = true;
    this.menuTarget.classList.remove("stuck");
    this.buttonTarget.tabIndex = -1;
  }
}
