import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import Mark from "mark.js";

export default class extends Controller {
  static targets = ["filterable", "input"];

  static debounces = ["search"];

  connect() {
    useDebounce(this, { wait: 100 });
  }

  search() {
    const lowerCaseFilterTerm = this.inputTarget.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    // Get targets
    this.filterableTargets.forEach((el) => {
      // Use data-filter-key for matching
      const filterableKey = el.dataset.filterKey;

      // Remove lingering display: none or fadeouts
      if (filterableKey.includes(lowerCaseFilterTerm)) {
        el.classList.remove("staff-search-hide");
      } else {
        // Set timeout to match CSS selector transition duration
        el.classList.add("staff-search-hide");
      }

      // Define mark.js options for highlighting
      const options = {
        className: "staff-search-result",
        acrossElements: true,
        element: "mark",
      };

      const instance = new Mark(el);
      // Wrap mark function in unmark to make sure old marks are erased
      instance.unmark({
        done() {
          if (lowerCaseFilterTerm.length > 1) {
            instance.mark(lowerCaseFilterTerm, options);
          }
        },
      });
    });
  }
}
