import { Controller } from "@hotwired/stimulus";
import { useMatchMedia } from "stimulus-use";

export default class extends Controller {
  static targets = ["toggler", "section"];

  connect() {
    useMatchMedia(this, {
      dispatchEvent: false,
      mediaQueries: {
        desktop: "(min-width: 984px)",
      },
    });
  }

  notDesktop() {
    this.toggleAdd();
  }

  isDesktop() {
    // Toggle is off by default, so this is in case user resizes from small to large window
    this.toggleRemove();
  }

  toggleSection() {
    this.togglerTarget.classList.toggle("toggle-open");
    this.sectionTarget.classList.toggle("toggle-open");
  }

  toggleAdd() {
    this.togglerTarget.dataset.action = "click->toggler#toggleSection";
  }

  toggleRemove() {
    this.togglerTarget.dataset.action = "";
  }
}
