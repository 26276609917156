/* eslint class-methods-use-this: "off" */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: String,
    duration: Number,
    link: String,
    auto: Boolean,
  };

  connect() {
    // if data-toast-dispatcher-auto-value is set to true,
    // the toast will auto-instantiate and dispatch without user interaction
    if (this.autoValue === true) {
      this.add();
    }
  }

  async add() {
    const toastGroup = document.querySelector(".gui-toast-group");
    if (!toastGroup) await this.createToastGroup();
    this.dispatch("emit", {
      prefix: "toast",
      detail: {
        message: this.messageValue,
        duration: this.durationValue,
        link: this.linkValue,
      },
    });
  }

  async createToastGroup() {
    // Create HTML with javascript
    const toastGroup = document.createElement("div");
    const referencedController = "toast-receiver";
    const receiveAction = "toast:emit@window->toast-receiver#receive";
    const hideGroupAction =
      "toast:hideGroup@window->toast-receiver#hideElement";

    toastGroup.classList.add("gui-toast-group");
    toastGroup.classList.add("visually-hidden");
    toastGroup.dataset.controller = referencedController;
    toastGroup.dataset.action = `${receiveAction} ${hideGroupAction}`;
    document.body.appendChild(toastGroup);
  }
}
