import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  showPastEvents(event) {
    // Prevent default radio button action
    event.preventDefault();
    // Use destructured shorthand for location
    const { location } = window.document;
    // Template with complete address
    const baseUri = `${location.protocol}//${location.host}${location.pathname}`;
    // Get upcoming or past filter string
    const { filter } = this.element.dataset;
    if (filter === "past") {
      // Redirect to filter param address
      Turbo.visit(`${baseUri}?event-filter=${filter}`);
    } else {
      // Upcoming is default, so remove params and use default url
      Turbo.visit(baseUri);
    }
  }
}
