// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import { Application, Controller } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("./**/*_controller.js");
registerControllers(application, controllers);
window.Controller = Controller;
window.Stimulus = application;
