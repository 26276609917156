import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    subpage: String,
    select: String,
    discipline: String,
  };

  showProjects(e) {
    /* Example editor content:
     * <a href="#aktuelle-prosjekter"
     *    data-controller="subpage-toggle"
     *    data-action="subpage-toggle#showProjects"
     *    data-subpage-toggle-subpage-value="aktuelle-prosjekter"
     *    data-subpage-toggle-select-value="aktuelle-prosjekter-filter"
     *    data-subpage-toggle-discipline-value="dans"
     *    data-external-links-target="ignore">Prosjekter</a>
     */
    e.preventDefault();
    // Get subpage dom node from urlValue
    const subpage = document.querySelector(`#${this.subpageValue}`);
    // Get select dropdown dom node
    const dropdown = document.querySelector(`#${this.selectValue}`);
    // Set select value with javascript
    dropdown.value = this.disciplineValue;
    // Dispatch change event to trigger filtering
    dropdown.dispatchEvent(new Event("change"));
    // Scroll to subpage
    subpage.scrollIntoView({ behavior: "smooth" });
  }

  toggleSubpage(e) {
    const subpage = document.querySelector(`#${this.urlValue}`);
    const toggler = subpage.querySelector("[data-toggler-target]");
    // Calls toggler_controller indirectly by simulating a click
    toggler.click();
    // Since it now scrolls too early to the anchor target,
    // we need to force a scrollIntoView to the correct target
    const anchor = e.currentTarget.getAttribute("href");
    const anchorTarget = document.querySelector(anchor);
    anchorTarget.scrollIntoView({
      behavior: "smooth",
    });
  }
}
