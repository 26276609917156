import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Animation functions
  // eslint-disable-next-line class-methods-use-this
  animateIn(el) {
    el.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 250,
      easing: "cubic-bezier(0.55, 0.085, 0.68, 0.53)",
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async animateOut(el) {
    el.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 250,
      easing: "cubic-bezier(0.55, 0.085, 0.68, 0.53)",
    });
  }

  // Run this before render, but after click.
  // Use await for the function, then a promise to ensure it's finished before resume
  async afterClick(event) {
    event.preventDefault();
    await this.animateOut(this.element);
    Promise.all(
      this.element.getAnimations().map((animation) => {
        return animation.finished;
      })
    ).then(() => {
      event.detail.resume();
    });
  }

  // Run this on every load. There's a legacy fallback in the body when Turbo isn't firing.
  afterLoad() {
    this.animateIn(this.element);
  }
}
